// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";
 
// GENERAL
body {
    overflow-x: hidden;
    background-color: @total-color;
}

.goshop-modal-content {
    display: none;
}

#container {
    min-height: 100vh;
    min-height: -webkit-fill-available;

    // IMPORTANT
    display: flex;
    flex-direction: column;

    > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

.header-sentinel {
    position: absolute;
    top: 0;
    left: 0;
}

.privacy-sentinel {
    height: 0;
    clear: both;
}

// DARK MODE TWEAKS
body.dark-mode {

    .shop-header {
        position: relative;

        &:before {
            .full-bg();
            background-color: @light-bg;
        }
    }

    .top-bar:before {
        border-bottom: 1px solid @border-color;
    }

    .dark-mode-toggle {
        background: @total-color;

        &:after {
            background: @border-color;
            border: 1px solid @border-color;
        }
    }

    .product-badges span,
    .detail-badges span {
        background: @border-color;

        &.detail-promo-badge,
        &.promo-badge {
            background: @main-color;
            color: @font-color;
        }
    }

    textarea {
        background-color: @total-color !important;
    }

    .form-hint {
        color: @sec-color;
    }

    .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all label {
        color: @sec-color;
    }

    .product {

        del {
            color: rgba(255, 255, 255, 0.6) !important;
        }

        .product-hover {
            background-color: rgba(20,20,20,0.9);
        }
    }

    .detail-pricing del {
        color: rgba(255, 255, 255, 0.6) !important;
    }

    .address-select-list li:not(.no-addresses) {
        background-color: @light-bg;

        strong {
            background-color: @light-bg;
        }
    }

    .product.product-name,
    a.blog-banner p,
    .detail-additional-infos > * {
        color: @footer-gray;
    }

    .detail-info-wrapper {
        border: 1px solid @border-color;
    }

    .close-filter-bottom {
        background: @border-color;
    }


    .product-wishlist {
        background: @border-color;

        &:hover {
        }

        &.existing {
            background: @main-color;
        }
    }

    #loading {
        background-color: rgba(255,255,255,.1);
    }

    .pagination {

        a {
            background-color: @border-color;
            border: 1px solid @border-color;

            &:hover {
                color: @font-color;
                border: 1px solid @main-color;
            }
        }

        li.selected > a {
            color: @font-color;
            background-color: @main-color;
            border: 1px solid @main-color;
        }
    }

    .promo-banner figure img {
        filter: invert(1);
    }

    #breadcrumb .breadcrumb-end {

        &:nth-of-type(1) {
            background: linear-gradient(to left, rgba(255, 255, 255, 0), #000, #000);
        }

        &:nth-of-type(2) {
            background: linear-gradient(to right, rgba(255, 255, 255, 0), #000, #000);
        }
    }

    .filter-trigger svg,
    .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button svg {
        filter: brightness(1);
    }

    #tree-top:hover .tree-top-hover,
    .tree-top-menu,
    .tree-top-menu ul {
        background-color: @border-color;
    }

    #detail-comments .comment > cite {
        background: @light-bg;
    }

    .comment-response {
        background: @light-bg;
    }

    button.availability-modal-trigger,
    .return-button,
    a.return-button {
        background-color: @border-color;

        &:hover {
            background-color: rgb(80, 80, 80) !important;
        }
    }

    input[type="text"],
    .detail-accordion,
    dd.color li:before,
    .detail-options dd:not(.color) span,
    .detail-options dd:not(.color) ul {
        border-color: @border-color;
    }

    @media (min-width: 1280px) {
        #tree nav {
            background-color: @light-bg;
        }

        #suggestions {
            background-color: @light-bg;
            border: 1px solid @border-color;
        }
    }

    @media (max-width: 1279px) {
        #tree ul:not(.cms-rwd) {
            background-color: @border-color;
        }
    }

    .sidebar-products-wrapper,
    .side-xsell-wrapper {
        background-color: @light-bg;
    }
}

//TOP BAR
.top-bar {
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 13px 0 14px;
    color: @footer-gray;
    display: flex;
    justify-content: center;

    > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        padding: 0 48px 0 24px;
    }

    &:before {
        .full-bg();
        background-color: #171717;
    }
}

body[data-type="cart"] {

    .top-bar {
        display: none !important;
    }
}

.top-bar-close {
    position: absolute;
    top: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    aspect-ratio: 1;
    color: @footer-gray;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        .small-icon();
        content: "\e927";
    }

    &:hover {
        color: white;
    }
}

// SHOP HEADER
.shop-header-container {
    .grid();
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-rows: auto auto;
    gap: 0 53px;
    padding: 24px 0;
    position: relative;

    &:after {
        .hr-after();
        bottom: 0;
    }
}

.logo {
    grid-row: 1 / 3;
    width: 180px;
    height: auto;

    img {
        .img-contain();
    }
}

body[data-type="cart"] {

    .logo {
        height: 100% !important;
        width: auto !important;
        padding: 10px 0;
    }
}

// HEADER TOP
.header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    white-space: nowrap;
}

// DARK MODE TOGGLE
.dark-mode-toggle {
    display: flex;
    height: 24px;
    aspect-ratio: ~"88/24";
    border-radius: 30px;
    background: @light-bg;
    position: relative;
    justify-self: flex-start;

    &:after {
        content: '';
        background: @sec-color;
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 30px;
        z-index: 1;
        transition: transform 0.1s ease-in-out;
    }

    > button {
        height: 100%;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;

        &:before {
            .small-icon();
            font-size: 16px;
        }

        &.dark:before {
            content: "\e926";
        }

        &.light:before {
            content: "\e925";
        }
    }
}

body.dark-mode .dark-mode-toggle:after {
    transform: translateX(-100%);
}

// HEADER SHIPMENT
.header-shipment {
    font-size: 14px;
    display: flex;
    align-content: center;
    gap: 4px;
    margin-right: auto;

    &:before {
        .small-icon();
        content: "\e91c";
        margin-right: 8px;
    }
}

// HEADER CMS
.mainmenu {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: auto;
    margin-right: -12px;

    a {
        text-transform: uppercase;
        color: @header-color;
        font-weight: 700;
        font-size: 14px;

        &:hover {
            color: @hover-color;
        }
    }

    li[title="Eko Strefa"] a {
        color: @success-color;
    }
}

// HEADER CONTACT DROPDOWN
.header-contact-trigger {
    text-transform: uppercase;
    color: @header-color;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-self: flex-end;
    position: relative;

    &:hover {
        color: @hover-color;
    }

    &:before {
        .small-icon();
        content: "\e91b";
        margin-right: 12px;
        color: @main-color;
    }

    &:after {
        .small-icon();
        content: "\e91a";
        margin-left: 6px;
    }

    &.open {

        &:after {
            transform: scale(-1);
        }

        .header-contact-dropdown {
            visibility: visible;
            opacity: 1;
            transform: none;
        }
    }
}

.header-contact-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    padding: 10px 18px;
    background: @light-bg;
    z-index: 99;
    border-radius: 8px;
    text-transform: initial;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.2s ease-in-out;
    border: 1px solid @border-color;
    .shadow();

    a {
        color: @header-color;
        font-weight: normal;
        display: flex;
        gap: 12px;
        align-items: center;
        font-size: 14px;
        padding: 8px 0 8px 32px;
        position: relative;

        &:hover {
            text-decoration: underline;
        }

        &:before {
            .small-icon();
            font-size: 20px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }

        &.main-phone:before {
            content: "\e911";
        }

        &.email:before {
            content: "\e913";
        }
    }

    &:has(.phone) {
        a.email {
            padding-top: 12px;
        }
    }
}

// HEADER BOTTOM
.header-bottom {
    display: flex;
    justify-content: space-between;
    gap: 48px;
    align-items: center;
}

// HEADER TOOLS
.header-tools-wrapper {
    display: flex;
    gap: 32px;
    margin-right: 6px;
}

.search-engine-trigger,
#wishlist-box,
.log-button,
#cart-box,
.tree-trigger {
    cursor: pointer;
    position: relative;
    padding-top: 40px;

    span {
        display: none;
    }

    &:before {
        font-family: icomoon;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: 32px;
        line-height: 1;
        color: @header-color;
    }

    em {
        color: @header-color;
        font-style: normal;
        text-transform: uppercase;
        font-size: 12px;
        display: block;
    }

    @media (min-width: 1280px) {
        &:hover {

            &:before,

            em {
                color: @main-color;
            }
        }
    }
}

.search-engine-trigger {

    &:before {
        content: "\e919";
    }
}

#wishlist-box:after,
#cart-box i {
    content: '';
    font-size: 0;
    position: absolute;
    top: 3px;
    right: 6px;
    width: 8px;
    height: 8px;
    background-color: @main-color;
    border-radius: 50%;
}

#wishlist-box {

    &:before {
        content: "\e918";
    }

    &:after {
        right: 13px;
    }

    &[data-wishlist-count="0"]:after {
        display: none;
    }
}

.log-button {

    &:before {
        content: "\e917";
    }
}

#cart-box {

    &:before {
        content: "\e916";
    }
}

.hamburger-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: center;

    span {
        display: block;
        width: 90%;
        height: 2px;
        border-radius: 1px;
        background-color: @header-color;

        &:nth-child(1) {
        }

        &:nth-child(2) {
        }

        &:nth-child(3) {
        }
    }
}

@media (min-width: 1280px) {
    .tree-trigger,
    .tree-canvas {
        display: none;
    }
}

// SEARCH ENGINE
.search-engine-trigger,
.search-engine-close {
    display: none;
}

@media (min-width: 1280px) {
    #search-engine {
        position: relative;
        flex: 1;
        max-width: 656px;
        z-index: 100;
        transition: transform .2s ease-in-out;
        margin-right: auto;
        margin-top: 10px;

        &.show,
        &.show-last-phrases {

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;
            }
        }

        &:not(.show-last-phrases) {

            .suggestion-last-phrases {
                display: none;
            }

            &:has(.suggestion-result-products:empty) {

                .no-products-info {
                    display: block !important;
                }
            }
        }

        input[type="text"] {
            width: 100%;
            height: 48px;
            padding: 0 64px 0 24px;
            background-color: @total-color;
            border-radius: 30px;
            color: @header-color;
            font-size: 16px;
            font-family: @main-font-family;
            border: 1px solid @border-color;

            &::-webkit-input-placeholder {
                color: @header-color;
            }
            &::-moz-placeholder {
                color: @header-color;
            }
            &:-ms-input-placeholder {
                color: @header-color;
            }
            &:-moz-placeholder {
                color: @header-color;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 64px;
            height: 48px;
            color: @header-color;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                content: "\e919";
                font-family: icomoon;
            }

            &:hover {
                color: @main-color;
            }
        }
    }
}

#suggestions {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

// TREE TOP
#tree-top {
    position: relative;
    z-index: 100;

    &:hover {

        .tree-top-hover {
            background-color: @light-bg;
            color: @header-color;
            border-radius: 30px 30px 0 0;

            .tree-top-hover-hamburger > i {
                background-color: @header-color;
            }

            &:after {
                transform: scaleY(-1);
            }
        }

        .tree-top-menu {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            transition-delay: .2s;
        }
    }
}

.tree-top-hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @main-color;
    border-radius: 30px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    z-index: 1;
    position: relative;
    padding: 12px 24px;
    white-space: nowrap;
    text-transform: uppercase;
    margin-top: 10px;
    transition:
        border-radius 0.2s ease-in-out,
        background-color 0.2s ease-in-out,
        color 0.2s ease-in-out;

    &:after {
        .small-icon();
        content: "\e91a";
        margin-left: 6px;
    }
}

.tree-top-hover-hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 24px;
    height: 17px;
    margin-right: 12px;

    i {
        height: 1px;
        width: 17px;
        background-color: white;
        transition: background-color 0.2s ease-in-out;

        &:nth-child(2) {
            align-self: flex-end;
            position: relative;
            right: 2px;
        }
    }
}

.tree-top-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 280px;
    background-color: @light-bg;
    border-radius: 0 8px 8px 8px;
    padding: 18px 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all .2s ease-out;
    font-size: 14px;

    li {
        position: relative;

        &:first-child {
            padding-top: 0;

            &:before {
                display: none;
            }
        }

        &:hover {

            > a {
                color: @main-color;
            }

            > ul {
                opacity: 1;
                visibility: visible;
                transform: translateX(0);
            }
        }
    }

    .parent > a {
        padding-right: 44px;
        position: relative;

        &:after {
            content: "\e91a";
            font-family: icomoon;
            position: absolute;
            top: 0;
            right: 24px;
            width: 24px;
            height: 100%;
            font-weight: normal;
            font-size: 24px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            transform: rotate(-90deg) translate(1px, 5px);
        }
    }

    a {
        display: block;
        white-space: nowrap;
        color: @header-color;
        font-weight: 700;
        padding: 6px 32px;
        text-transform: uppercase;
    }

    ul {
        position: absolute;
        top: -18px;
        left: calc(100% + 1px);
        background-color: @light-bg;
        border-radius: 8px;
        padding: 18px 0;
        opacity: 0;
        visibility: hidden;
        transform: translateX(-10px);
        transition: all .2s ease-in-out;
        min-width: 280px;

        &:before {
            content: '';
            height: 100%;
            width: 6px;
            background: transparent;
            position: absolute;
            top: 0;
            left: -3px;
        }
    }
}

// PRODUCTS
@keyframes productImageOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.products-list {
    .grid();
    grid-template-columns: repeat(var(--prodCols), minmax(0, 1fr));
    gap: var(--prodGapVertical) var(--prodGap);
    width: 100%;

    &:empty {
        display: none;
    }
}

.products-list .product,
.product.swiper-slide {

    &:after {
        position: absolute;
        right: calc(-0.5 * var(--prodGap));
        top: 0;
        content: '';
        height: 100%;
        width: 1px;
        background: @border-color;
    }

    &:last-child,
    &:not(.swiper-slide):nth-child(4n) {

        &:after {
            display: none;
        }
    }
}
body[data-type="mainpage"] .products-list .product,
.bought-with-wrapper .products-list .product { // mainpage and detail

    &:not(.swiper-slide):nth-child(4n):after {
        display: grid;
    }

    &:not(.swiper-slide):nth-child(5n):after {
        display: none;
    }
}


.product {
    position: relative;
    .grid();
    grid-template-rows: auto 1fr;
    gap: 16px;

    &.swiper-slide {
        box-sizing: border-box;

        .product-name {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            --fontSize: 14px;
            display: -webkit-box !important;
            height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.product-badges {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0;
    left: 0;
    font-size: 12px;
    font-weight: 700;
    color: white;
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: center;

    span {
        background-color: @hover-color;
        padding: 4px 8px;

        &.promo-badge {
            background-color: @error-color;
            margin-left: auto;
        }
    }
}

.product-image {
    aspect-ratio: 1 / 1;
    position: relative;
    transition: transform .1s ease-in-out;

    img {

        &:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            display: none;
            opacity: 0;
        }
    }
}

a.product-name {
    font-weight: normal;
    display: block;
    color: @header-color;
    transition: opacity .1s ease-in-out;
    z-index: 1; // IMPORTANT
    font-size: 14px;

    &:after {
        content: '';
        position: absolute;
        inset: 0;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.product-bottom-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 6px;
    z-index: 1;
}

.product-price {
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    b, del {
        line-height: 1.2;

        &:after {
            content: ' zł';
        }
    }

    b {
        font-size: 14px;
        font-weight: 700;
        color: @header-color;
    }

    del {
        font-size: 12px;
        color: rgba(23, 23, 23, 0.6);
    }
}

.product-add,
.product-wishlist {
    height: 32px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:before {
        .small-icon();
        font-size: 20px;
    }
}

.product-add {
    background-color: @main-color;
    color: white;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;
    gap: 10px;
    padding: 0 12px;

    &:before {
        content: "\e916";
    }

    &.disabled {
        display: none;
    }

    &:hover {
        background-color: #CC1820;
    }
}

.product-wishlist {
    margin-left: auto;
    background-color: @light-bg;
    color: @font-color;
    font-size: 20px;
    aspect-ratio: 1;
    justify-content: center;

    &:before {
        content: "\e918";
    }

    &:hover {
        color: @main-color;
        background-color: @sec-color;
    }

    &.existing {
        background-color: @error-color;
        color: white;
    }
}

.product-hover {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(255,255,255,0.9);
    padding: 12px 16px;
    transform: translateY(8px);
    transition: all .2s ease-in-out;

    p {
        color: @font-color;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box !important;
        max-height: ~"calc(12px + 3 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (min-width: 1280px) {
    .product:hover {

        a {
            text-decoration: underline;
        }

        .product-hover {
            opacity: 1;
            transition-delay: .2s;
            transform: translateY(0px);
        }
    }

    .product.has-photo:hover .product-image img {

        &:first-of-type {
            opacity: 0;
            transition-delay: 0.2s;
        }

        &:nth-of-type(2) {
            display: block;
            animation: productImageOpacity .2s ease-in-out forwards;
            animation-delay: 0.2s;
        }
    }
}

// BREADCRUMB
#breadcrumb {
    padding: 14px 0 10px 0;
    margin-bottom: -48px;
    position: relative;

    &:after {
        .hr-after();
        bottom: 0;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '-';
            margin: 0 6px;
        }

        &:last-child {

            &:after {
                display: none;
            }
        }
    }

    a,
    span {
        color: @font-color;
        display: block;
        font-size: 14px;
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}
